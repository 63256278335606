import { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const entryAnimation = (mainRef, resetScroll) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const tl = gsap.timeline();

        tl.to(mainRef.current, {
            paddingTop: 0,
            duration: 0.6,
            delay: 0.45,
            ease: 'Power1.easeInOut',
        });
        tl.eventCallback('onComplete', () => {
            if (resetScroll) {
                ScrollTrigger.refresh(true);
            }
            document.body.style.position = 'static';
            document.body.style.overflowY = 'auto';
        });
    }, [mainRef, resetScroll]);
};

export default entryAnimation;
