import React, {createRef, useEffect, useMemo, useRef, useState} from 'react';
import './Navbar.scss';
import {graphql, useStaticQuery} from 'gatsby';
import gsap from 'gsap';
import clsx from 'clsx';
import CookieBot from 'react-cookiebot';
import Exit from '../../assets/x.svg';

const domainGroupId = '099420e2-d58a-4bb4-a4d4-739bbd511baf';
import {Helmet} from 'react-helmet';

function Navbar({breakpointRef, invertLogo = false, showMover = true}) {
    const repeatN = (text, times) => new Array(times).fill(null).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={i}>{text}</span>
    ));
    const BASE_DELAY = 0.05;
    const BASE_DELAY_FOOTER = 0.1;

    const blackMover = useRef(null);
    const navbarRef = useRef(null);
    const [menuToggle, setMenuToggle] = useState(false);
    const [isHidden, setIsHidden] = useState(true);
    const navbar = useRef(null);
    const additionalMenuRef = useRef(null);
    const socialRef = useRef(null);
    const menuButton = useRef(null);
    const menuRef = useRef(null);
    const data = useStaticQuery(query);
    const mainSettings = data.allGetHeroMainSettings.nodes[0];
    const logo = mainSettings.logo[0]
        ? mainSettings.logo[0].localFile.publicURL
        : '';
    const logoAlternative = mainSettings.logo_alternative[0]
        ? mainSettings.logo_alternative[0].localFile.publicURL
        : '';
    const smallLogo = mainSettings.small_logo[0]
        ? mainSettings.small_logo[0].localFile.publicURL
        : '';
    const mainMenu = data.allGetHeroMainSettings.nodes[0].menu.filter(
        (menuItem) => menuItem.main,
    );
    const standardMenu = data.allGetHeroMainSettings.nodes[0].menu.filter(
        (menuItem) => !menuItem.additional,
    );
    const additionalMenu = data.allGetHeroMainSettings.nodes[0].menu.filter(
        (menuItem) => menuItem.additional,
    );
    const [isMobile, setIsMobile] = useState(false);

    const MenuAnimatedButton = ({menuItem, index}) => {
        const menuItemRef = createRef();
        const tl = gsap.timeline({delay: 0.25});
        useEffect(() => {
            if (menuItemRef.current) {
                tl.fromTo(menuItemRef.current, {y: '150%'},
                    {y: '15%', duration: 0.5, delay: index * BASE_DELAY, ease: 'Power2.easeOut'});
            }
        });
        return (

            <a href={menuItem.link} key={menuItem.link}>
                <div className="menu__items" key={menuItem.id}>
                    <div className="item">
                        <span className="item__name" ref={menuItemRef}>{menuItem.name}</span>
                    </div>
                    <div className="item item--alternative">
                        {repeatN(menuItem.name, 10)}
                    </div>
                </div>
            </a>
        );
    }


    useEffect(() => {
        if (!isHidden) {
            const tl = gsap.timeline({delay: 0.25});
            if (socialRef.current.children) {
                tl.fromTo(socialRef.current.children, {y: '350%'},
                    {y: '0', delay: 0.1, duration: 0.3, stagger: 0.05, ease: 'Power2.easeOut'});
            }
        }
    }, [isHidden]);
    const menuAnimation = () => {
        if (menuRef && menuRef.current) {
            const menuElement = menuRef.current;

            if (isHidden) {
                gsap.to([menuElement], {right: 0});

                setTimeout(() => {
                    document.body.style.overflow = 'hidden';
                }, 500);
                setIsHidden(false);
            } else {
                gsap.to([menuElement], {right: '100%'});
                setIsHidden(true);
                document.body.style.overflow = 'auto';
            }
        }
    };
    /**
     * Change section class if scrolled below provided element
     */
    const handleScroll = () => {
        if (window.scrollY > breakpointRef.current.offsetTop) {
            navbar.current.classList.add('navbar-component--collapsed');
            additionalMenuRef.current.classList.add('navbar--hidden');
            menuButton.current.classList.remove('btn-menu');
        } else {
            navbar.current.classList.remove('navbar-component--collapsed');
            additionalMenuRef.current.classList.remove('navbar--hidden');
            menuButton.current.classList.add('btn-menu');
        }
    };

    useEffect(() => {
        if (showMover) {
            const tl = gsap.timeline();
            if (blackMover.current !== null) {
                tl.to(blackMover.current, {
                    top: '-100vh',
                    ease: 'Power1.easeInOut',
                    duration: 0.6,
                    delay: 0.5,
                });
            }
        }

        if (breakpointRef.current) {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
        return () => {
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function detectMob() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    useEffect(() => {
        if (navigator) {
            setIsMobile(detectMob());
        }
    })

    return (
        <section ref={navbarRef} className="navbar-layout">
            <div className="horizontalOverlay">
                <h2>
                    {isMobile ? 'Używaj orientacji pionowej.' : ' Powiększ okno przeglądarki'}
                </h2>
            </div>
            <Helmet>
                {data.site.siteMetadata.env === 'production' && !data.site.siteMetadata.stage && (
                    <script
                        id="Cookiebot"
                        src="https://consent.cookiebot.com/uc.js"
                        data-cbid="099420e2-d58a-4bb4-a4d4-739bbd511baf"
                        data-blockingmode="auto"
                        type="text/javascript"
                    />
                )}
            </Helmet>
            <CookieBot domainGroupId={domainGroupId}/>
            <div className="navbar-component" ref={navbar}>
                <a href="/">
                    {invertLogo ? (
                        <img
                            src={logoAlternative}
                            alt="Logo"
                            className="navbar-component__logo navbar-component__logo--standard"
                        />
                    ) : (
                        <img
                            src={logo}
                            alt="Logo"
                            className="navbar-component__logo navbar-component__logo--standard"
                        />
                    )}
                    <img
                        src={smallLogo}
                        alt="Logo"
                        className="navbar-component__logo navbar-component__logo--small"
                        style={{opacity: 0, display: 'none'}}
                    />
                </a>
                <div className="navbar-component__navigation">
                    <ul className="navbar" ref={additionalMenuRef}>
                        {mainMenu.map((menuItem) => (
                            <li className="navbar__item " key={menuItem.name}>
                                <a
                                    href={menuItem.link}
                                    key={menuItem.name}
                                    className="btn btn--borderless"
                                >
                                    {menuItem.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <button
                        ref={menuButton}
                        onClick={menuAnimation}
                        className="btn-menu btn btn--borderless "
                    >
                        +menu
                    </button>
                </div>
            </div>
            {showMover && (<div ref={blackMover} className="blackMover"/>)}
            <div ref={menuRef} className="menu-bg" id="menu">
                <div className="menu">
                    <div className="menu__top-bar">
                        <a href="/">
                            {invertLogo ? (
                                <img
                                    src={logoAlternative}
                                    alt="Logo"
                                    className="navbar-component__logo navbar-component__logo--standard"
                                />
                            ) : (
                                <img
                                    src={logo}
                                    alt="Logo"
                                    className="navbar-component__logo navbar-component__logo--standard"
                                />
                            )}
                        </a>
                        <img
                            className="menu__top-bar__exit"
                            onClick={menuAnimation}
                            src={Exit}
                            alt="Exit"
                        />
                    </div>
                    <div className="menu-body">
                        <div className="menu-items-wrapper">
                            {useMemo(() => (
                                !isHidden
                                && standardMenu.map((menuItem, index) => (
                                    <MenuAnimatedButton
                                        key={menuItem.name}
                                        menuItem={menuItem}
                                        index={index}
                                    />
                                ))
                            ), [isHidden])}
                        </div>
                        <div className="footer">
                            <div className="menu__additional">
                                {!isHidden && additionalMenu
                                && additionalMenu.map((menuItem, index) => {
                                    const itemRef = createRef();
                                    const tl = gsap.timeline({delay: 0.25});
                                    setTimeout(() => {
                                        if (itemRef.current) {
                                            tl.to(itemRef.current,
                                                {y: '15%', duration: 0.5, delay: index * BASE_DELAY_FOOTER});
                                        }
                                    }, 10);
                                    return (
                                        <a href={menuItem.link} key={menuItem.link}>
                                            <button ref={itemRef} className="menu__additional__item btn btn--borderless">
                                                {menuItem.name}
                                            </button>
                                        </a>
                                    );
                                })}
                            </div>
                            <div ref={socialRef} className="menu__social">
                                <a
                                    href={data.allGetHeroMainSettings.nodes[0].instagram_link}
                                    target="_blank"
                                    className="btn btn--social-media"
                                    rel="noreferrer"
                                >
                                    <svg
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 30 30"
                                    >
                                        <path
                                            className="btn--social-media__icon"
                                            d="M2,11.9c0-4.1,0-6.2,1.1-7.6c0.3-0.5,0.7-0.9,1.2-1.2C5.7,2,7.8,2,11.9,2h6.9c4.1,0,6.2,0,7.6,1.1
                                            c0.5,0.3,0.9,0.7,1.2,1.2c1.1,1.4,1.1,3.5,1.1,7.6v6.9c0,4.1,0,6.2-1.1,7.6c-0.3,0.5-0.7,0.9-1.2,1.2c-1.4,1.1-3.5,1.1-7.6,1.1h-6.9
                                            c-4.1,0-6.2,0-7.6-1.1c-0.5-0.3-0.9-0.7-1.2-1.2C2,25,2,22.9,2,18.8V11.9z M15.3,8.3c-3.9,0-7.1,3.2-7.1,7.1c0,3.9,3.2,7.1,7.1,7.1
                                            c3.9,0,7.1-3.2,7.1-7.1C22.4,11.4,19.2,8.3,15.3,8.3z M15.3,19.7c-2.4,0-4.4-2-4.4-4.4c0-2.4,2-4.4,4.4-4.4c2.4,0,4.4,2,4.4,4.4
                                            C19.7,17.8,17.8,19.7,15.3,19.7z M23.9,7.7c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9
                                            C23.4,6.8,23.9,7.2,23.9,7.7z"
                                        />
                                    </svg>
                                </a>
                                <a
                                    href={data.allGetHeroMainSettings.nodes[0].facebook_link}
                                    target="_blank"
                                    className="btn btn--social-media"
                                    rel="noreferrer"
                                >
                                    <svg
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 30 30"
                                    >

                                        <path
                                            className="btn--social-media__icon"
                                            d="M21.5,2l-3.1,0c-3.5,0-5.8,2.6-5.8,6.6v3.1H9.5C9.2,11.7,9,12,9,12.3v4.4c0,0.3,0.2,0.6,0.5,0.6h3.1v11.2
                                            c0,0.3,0.2,0.6,0.5,0.6h4.1c0.3,0,0.5-0.2,0.5-0.6V17.2h3.7c0.3,0,0.5-0.2,0.5-0.6l0-4.4c0-0.1-0.1-0.3-0.1-0.4
                                            c-0.1-0.1-0.2-0.2-0.3-0.2h-3.7V9.1c0-1.2,0.3-1.9,1.7-1.9l2.1,0C21.8,7.2,22,7,22,6.7V2.6C22,2.3,21.8,2,21.5,2z"
                                        />
                                    </svg>
                                </a>
                                <a
                                    href={data.allGetHeroMainSettings.nodes[0].youtube_link}
                                    target="_blank"
                                    className="btn btn--social-media"
                                    rel="noreferrer"
                                >
                                    <svg
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 30 30"
                                    >
                                        <path
                                            className="btn--social-media__icon"
                                            d="M26,5.6c1.2,0.3,2.1,1.3,2.5,2.5c0.6,2.2,0.6,6.8,0.6,6.8s0,4.5-0.6,6.7c-0.3,1.2-1.3,2.1-2.5,2.5
                                            c-2.2,0.6-11,0.6-11,0.6s-8.7,0-11-0.6c-1.2-0.3-2.1-1.3-2.5-2.5C1,19.4,1,14.8,1,14.8s0-4.5,0.6-6.7C1.9,6.9,2.9,5.9,4,5.6
                                            C6.2,5,15,5,15,5S23.8,5,26,5.6z M19.5,14.8L12.2,19v-8.4L19.5,14.8z"
                                        />
                                    </svg>
                                </a>
                                <a
                                    href={data.allGetHeroMainSettings.nodes[0].linkedin_link}
                                    target="_blank"
                                    className="btn btn--social-media"
                                    rel="noreferrer"
                                >
                                    <svg
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 30 30"
                                    >

                                        <g>
                                            <defs>
                                                <rect id="SVGID_1_" x="4" y="4" width="23" height="23"/>
                                            </defs>
                                            <clipPath id="SVGID_2_">
                                                <use xlinkHref="#SVGID_1_"/>
                                            </clipPath>
                                            <g className="st0">
                                                <path
                                                    className="btn--social-media__icon"
                                                    d="M27,27L27,27L27,27l0-8.4c0-4.1-0.9-7.3-5.7-7.3c-2.3,0-3.9,1.3-4.5,2.5h-0.1v-2.1h-4.6V27h4.8v-7.6
			                                        c0-2,0.4-3.9,2.9-3.9c2.4,0,2.5,2.3,2.5,4.1V27H27z"
                                                />
                                                <path
                                                    className="btn--social-media__icon"
                                                    d="M4.4,11.6h4.8V27H4.4V11.6z"
                                                />
                                                <path
                                                    className="btn--social-media__icon"
                                                    d="M6.8,4C5.2,4,4,5.2,4,6.8c0,1.5,1.2,2.8,2.8,2.8c1.5,0,2.8-1.3,2.8-2.8C9.5,5.2,8.3,4,6.8,4L6.8,4z"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                                <div className="dropdown-wrapper">
                                    <div className="dropdown">
                                        {menuToggle
                                        && (
                                            <div className="dropdown-content">
                                                <a
                                                    href="/o-nas/en"
                                                    className="btn btn--borderless"
                                                >
                                                    EN
                                                </a>
                                                <a
                                                    href="/o-nas/chn"
                                                    className="btn btn--borderless"
                                                >
                                                    CHN
                                                </a>
                                            </div>
                                        )}
                                        <div className="toggle-wrapper">
                                            <a href="/o-nas/pl" className="btn btn--borderless">PL</a>
                                            <button
                                                className={
                                                    clsx(menuToggle
                                                        && 'toggle-button-on ', 'toggle-button btn btn--borderless')
                                                }
                                                onClick={() => {
                                                    setMenuToggle((prevState) => !prevState);
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const query = graphql`
  query NavSettings {
    site {
      siteMetadata {
        stage
        env
      }
    }
    allGetHeroMainSettings {
      nodes {
        linkedin_link
        instagram_link
        youtube_link
        facebook_link
        menu {
          name
          main
          additional
          link
        }
        logo {
          localFile {
            publicURL
          }
        }
        logo_alternative {
          localFile {
            publicURL
          }
        }
        small_logo {
          localFile {
            publicURL
          }
        }
        small_logo_alternative {
          localFile {
            publicURL
          }
        }
        favicon {
          extension
          id
        }
      }
    }
  }
`;

export default Navbar;
