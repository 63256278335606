import gsap from 'gsap';
import {useEffect, useState} from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';

const fadeInElementAnimation = (elements, startValue = 80) => {
    const [hasRun, setHasRun] = useState(false);
    gsap.registerPlugin(ScrollTrigger);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
    if(!hasRun) {
        elements.forEach((ref) => {
            gsap.fromTo(ref.current, { opacity: 0 }, { delay: 0.2,
                duration: 0.6,
                opacity: 1,
                scrollTrigger: {
                    trigger: ref.current,
                    start: `top ${startValue}%`,
                } });

            gsap.fromTo(ref.current, { y: '150px' }, {
                    opacity: 1,
                    duration: 0.8,
                    ease: 'Power2.easeOut',
                    y: '0',
                    scrollTrigger: {
                        trigger: ref.current,
                        start: `top ${startValue}%`,
                        onEnter: () => setHasRun(true)
                    },
                }
            );
        });
    }
    });
};
export default fadeInElementAnimation;
