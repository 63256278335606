import React, {useRef} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import './Footer.scss';
import Instagram from '../../assets/social-icons/instagram.svg';
import Facebook from '../../assets/social-icons/facebook.svg';
import Youtube from '../../assets/social-icons/youtube.svg';
import LinkedIn from '../../assets/social-icons/linkedin.svg';
import fadeInElementAnimation from "../../resources/fade-in-element-animation";

const Footer = ({ showOnlyBasic }) => {
    const data = useStaticQuery(query);
    const footer = data.allFooter.nodes[0];
    const mainSettings = data.allGetHeroMainSettings.nodes[0];
    const footerRef = useRef(null);
    const {
        footer_header,
        instagram_link,
        facebook_link,
        youtube_link,
        linkedin_link,
        privacy_policy_text,
        privacy_policy_link,
        copyright_info_text,
    } = { ...footer, ...mainSettings };
    fadeInElementAnimation([footerRef], '90');
    return (
        <section ref={footerRef} className="footer-section">
            {!showOnlyBasic && (
                <>
                    <div className="d-flex">
                        {/* eslint-disable-next-line react/no-danger */}
                        <h1 dangerouslySetInnerHTML={{ __html: footer_header }} />
                    </div>
                    <a className="btn btn--borderless " href="/kontakt">Skontaktuj się z nami</a>
                </>
            )}
            <div className="footer-section__social-icons">
                <a
                    href={instagram_link}
                    target="_blank"
                    className="btn btn--borderless btn--social-media"
                    rel="noreferrer"
                >
                    Instagram
                    <img src={Instagram} alt="Instagram" />
                </a>
                <a
                    href={facebook_link}
                    target="_blank"
                    className="btn btn--borderless btn--social-media"
                    rel="noreferrer"
                >
                    Facebook
                    <img src={Facebook} alt="Facebook" />
                </a>
                <a
                    href={youtube_link}
                    target="_blank"
                    className="btn btn--borderless btn--social-media"
                    rel="noreferrer"
                >
                    Youtube
                    <img src={Youtube} alt="Youtube" />
                </a>
                <a
                    href={linkedin_link}
                    target="_blank"
                    className="btn btn--borderless btn--social-media"
                    rel="noreferrer"
                >
                    LinkedIn
                    <img src={LinkedIn} alt="LinkedIn" />
                </a>
            </div>
            <div className="footer-section__bottom-bar">
                <p>
                    {copyright_info_text}
                </p>
                <a href={privacy_policy_link} className="privacy-policy-link">
                    {privacy_policy_text}
                </a>
                <p>
                    <span>design </span>
                    <a href="https://www.nuostudio.pl/" target="_blank" rel="noreferrer">NUO</a>
                    <span> | dev </span>
                    <a href="https://codewave.eu/" target="_blank" rel="noreferrer">CODEWAVE</a>
                    <span> | powered by </span>
                    <a href="https://flotiq.com/" target="_blank" rel="noreferrer">FLOTIQ</a>
                </p>
            </div>
        </section>
    );
};

const query = graphql`
    query FooterSettings {
      site {
        siteMetadata {
          stage
        }
      }
      allGetHeroMainSettings {
        nodes {
          linkedin_link
          instagram_link
          youtube_link
          facebook_link
        }
      }
      allFooter {
        nodes {
          footer_header
          privacy_policy_text
          privacy_policy_link
          copyright_info_text
        }
      }
    }`;

export default Footer;
